import { Container, Link, Typography } from "@mui/material";
import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => {
  return (
    <Layout hideTopBar>
      <Container
        maxWidth="sm"
        sx={{
          my: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Page not found</Typography>
        <Typography sx={{ mt: 2 }}>
          We couldn't find what you were looking for.
        </Typography>
        <Link href="/" sx={{ mt: 1 }}>
          Go home
        </Link>
        .
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
